import ActionsBar from 'components/ActionsBar';
import Dropdown from 'components/Dropdown';
import H3 from 'components/H3';
import React, { ReactElement, useCallback, useState } from 'react';
import { connect } from 'react-redux';
import State from 'types/state';
import ITextValue from 'types/textValue';
import * as targetResultSelectors from 'store/selectors/targetResult';
import * as reportResultActions from 'store/actions/reportResult';
import DatePicker from 'components/DatePicker';
import QueryBuilderItem from 'components/QueryBuilder/components/QueryBuilderItem';

interface IProps {
  exportConfigOptions: ITextValue[];
  onCancel: () => void;
  handleConfigOptionExport: (
    configOptionId: string,
    effectiveDates: { startDate: string; endDate: string },
    toggleModalHook: (isOpen: boolean) => void,
  ) => void;
}

const ISpotSegmentModal = ({
  exportConfigOptions = [],
  onCancel,
  handleConfigOptionExport,
}: IProps): ReactElement => {
  const [configOption, setConfigOption] = useState<string>('');
  const [effectiveDateRange, setDateRange] = useState<{
    startDate: string;
    endDate: string;
  }>({ startDate: '2024-01-01', endDate: '2024-01-01' });
  const handleDateRangeChange = useCallback(
    (startDate: string, endDate: string): void => {
      setDateRange({ startDate, endDate });
    },
    [],
  );
  return (
    <div>
      <header>
        <H3>Please select an iSpot client to upload to</H3>
      </header>
      <section>
        <QueryBuilderItem label="Selected Client">
          <Dropdown
            options={exportConfigOptions}
            onChange={(val) => setConfigOption(val)}
            modalDropdown
            placeholder="Client"
          />
        </QueryBuilderItem>
        <br />
        <QueryBuilderItem label="Effective Dates">
          <DatePicker
            testId="date-picker"
            showSmartSelection={false}
            showFloating
            showNumOfDays={false}
            endDate={effectiveDateRange.endDate}
            trackingId="ispot-segment-date-picker"
            handleChange={handleDateRangeChange}
            maxDate="2035-12-31"
            minDate="2024-01-01"
            startDate={effectiveDateRange.startDate}
          />
        </QueryBuilderItem>
      </section>
      <ActionsBar
        okLabel="Confirm and Export"
        onOK={() =>
          handleConfigOptionExport(configOption, effectiveDateRange, onCancel)
        }
        onCancel={onCancel}
        disabledOK={!configOption}
      />
    </div>
  );
};

const mapStateToProps = (
  state: State,
): Pick<IProps, 'exportConfigOptions'> => ({
  exportConfigOptions: targetResultSelectors.getExportConfigOptions(state),
});

const mapDispatchToProps = {
  handleConfigOptionExport: reportResultActions.handleConfigOptionExport,
};

export default connect(mapStateToProps, mapDispatchToProps)(ISpotSegmentModal);
